<template>
  <div>
    <ModalTemplate :showModal="isModalActive" :width="widthModal" @closeModal="closeModal">
      <template v-slot:headerModal>
        <div class="is-flex is-justify-content-space-between is-align-items-center">
          <div class="is-flex is-justify-content-space-between is-align-items-center">
            <EditSquare color="#ffffff" class="mr-2" size="23" />
            <p class="averta-bold label-18">
              Ubah Status Absen
            </p>
          </div>
          <div class="is-clickable" @click="closeModal">
            <!-- <b-icon icon="close"></b-icon> -->
             <XMarkIcon />
          </div>
        </div>
      </template>
      <template v-slot:bodyModal>
        <div class="wrap-body-modal">
          <!-- ALERT -->
          <div class="bg-pink-3 px-3 py-2 flex-center-vertical">
            <AlertIcon />
            <p class="color-red ml-2 line-height-16 averta-bold label-13">Perubahan status absen secara manual akan mempengaruhi riwayat progress untuk proyek ini pada halaman Report Progress</p>
          </div>

          <!-- HEADER -->
          <div class="layout-header">
            <!-- Wrap Detail Tukang -->
            <div class="tukang-wrapper" v-if="dataWorkerAbsent">
              <!-- Worker Data -->
              <div class="header-tukang">
                <div class="is-flex is-align-items-center mb-4"
                :class="{'cursor-pointer': dataWorkerAbsent && dataWorkerAbsent.certificates && dataWorkerAbsent.certificates.length > 0 }"
                @click="dataWorkerAbsent && dataWorkerAbsent.certificates && dataWorkerAbsent.certificates.length > 0 ?
                 handleOpenModalsPreviewSertifikat(dataWorkerAbsent.worker_id, dataWorkerAbsent) : '-' ">
                  <!-- Image Worker -->
                  <div class="tukang-img flex-center mr-3">
                    <img :src="dataWorkerAbsent.worker_image" alt="worker-image" />
                  </div>
                  <!-- Image Worker -->

                  <!-- Skill & Name Worker -->
                  <div class="wrapper-skill-name">
                    <div class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center">
                      <p class="averta-bold mb-1">{{ dataWorkerAbsent.worker_name }}</p>
                      <div class="badge-new-comment ml-3 gap-1"
                      v-if="dataWorkerAbsent && dataWorkerAbsent.certificates && dataWorkerAbsent.certificates.length > 0">
                        <SertifikatBadgeIcon />
                        <p class="m-0 label-10 averta-bold-italic color-green">
                          Bersertifikat
                        </p>
                      </div>
                    </div>
                    <div class="is-flex is-align-items-center">
                      <img :src="dataWorkerAbsent.skill_image" :alt="dataWorkerAbsent.skill_name" class="skill-img" />
                      <p>{{ dataWorkerAbsent.skill_name }}</p>
                      <p class="mx-2">&#8226;</p>
                      <p class="text-grey"><i>ID{{ dataWorkerAbsent.worker_id }}</i></p>
                    </div>
                  </div>
                  <!-- Skill & Name Worker -->
                </div>
              </div>
              <!-- Worker Data -->
              <!-- Status Tukang -->
              <div class="slot-wrapper">
                <div class="is-flex is-align-items-center">
                  <p class="averta-bold label-14 mr-2">Slot</p>
                  <p class="ml-5 mr-1 text-grey">:</p>
                  <p class="averta-reguler label-14 text-grey">
                    {{ formatDate(dataWorkerAbsent.selected_data) }}
                  </p>
                </div>
                <div class="is-flex is-align-items-center">
                  <p class="averta-bold label-14">Status</p>
                  <p class="ml-4 mr-1 text-grey">:</p>
                  <p class="averta-reguler label-14 text-grey">
                    {{ dataWorkerAbsent.attendance_status ? 'Hadir' : 'Tidak Hadir' }}
                  </p>
                </div>
              </div>
              <!-- Status Tukang -->
            </div>
            <!-- Wrap Detail Tukang -->
          </div>
          <!-- END HEADER -->
          <!-- BODY -->
          <div class="wrapper-body">
            <div class="wrapper-status-hour" :style="[isMobile ? {} : { 'border-right': '6px solid rgba(225, 225, 225, 1)' }]">
              <div class="py-4 px-4">
                <p class="averta-black label-14">Pilih Status Baru</p>
                <div class="is-flex is-flex-direction-column p-3 wrapper-radio">
                  <b-field>
                    <b-radio v-model="absent" :native-value=true type="is-info">
                      <p class="averta-reguler label-14 color-black-primary">
                        Hadir
                      </p>
                    </b-radio>
                  </b-field>
                  <b-field>
                    <b-radio v-model="absent" :native-value=false type="is-info">
                      <p class="averta-reguler label-14 color-black-primary">
                        Tidak Hadir
                      </p>
                    </b-radio>
                  </b-field>
                </div>
              </div>
              <div class="py-4 px-4">
                <p class="averta-black label-14">Jam Kehadiran</p>
                <div class="text-input-wrapper" :class="{'isDisabled' : !isDisabledTime}">
                  <ClockCircle />
                  <input
                    type="time"
                    class="averta-bold ml-2 no-clock-icon"
                    v-model="time"
                    @input="handleInputTime"
                    :disabled="!isDisabledTime"
                  />
                </div>
              </div>
            </div>
            <div v-if="!absent && isAllPhotoBeforeEmpty" class="wrapper-empty">
              <div class="content-empty">
                <ProyekEmptyIcon width="120" height="120" />
                <div>
                  <p class="averta-bold label-14 color-black-primary">
                    Untuk upload foto before-after, ubah status kehadiran menjadi “Hadir”
                  </p>
                </div>
              </div>
            </div>
            <div v-else-if="absent || !isAllPhotoBeforeEmpty" class="wrapper-upload p-3">
              <div>
                <p class="label-16"><span class="averta-black">Upload Foto Pengerjaan Proyek</span> <span class="color-grey">| Maks. {{ maxPhotoCount }} Foto</span></p>
                <p class="is-italic color-blue-1 label-12">*Pastikan foto sesudah berpasangan dengan foto sesudahnya</p>
              </div>
              <div class="is-flex mt-3">
                <div class="mr-3">
                  <div class="border-top-dotted">
                    <div class="is-flex is-align-items-center is-justify-content-center">
                      <div v-if="absent || !isAllPhotoBeforeEmpty">
                        <div v-for="(photoItem, idx) in dataFotoBefore" :key="idx" class="mb-3">
                          <p class="mb-2 label-14"> {{ getPhotoTitle(idx, 'before') }} </p>
                          <div class="wrapper-preview is-relative">
                            <div
                              v-if="photoItem.can_delete && absent"
                              class="x-mark-rounded cursor-pointer"
                              @click="handleDeletePhoto({ idx, status: 'before' })"
                            >
                              <img :src="require('@/assets/img/close-circle.png')" alt="delete-image" />
                            </div>
                            <div
                              v-if="photoItem.preview"
                              class="wrapper-add-file"
                              :class="{ 'cursor-pointer': absent }"
                            >
                              <img :src="photoItem.preview" alt="">
                            </div>
                            <div v-else-if="!photoItem.preview">
                              <img v-if="!absent" src="@/assets/img/upload-photo-disable.png" alt="">
                              <label v-else class="cursor-pointer" for="beforeFoto">
                                <input
                                  id="beforeFoto"
                                  ref="beforeFoto"
                                  type="file"
                                  @change="e => handleFileUpload(e, 'before')"
                                  accept="image/*"
                                  hidden
                                />
                                <img @click="handleTempIndex(idx)" :src="require('@/assets/img/upload-photo.png')" alt="" />
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="border-top-dotted">
                    <div class="is-flex is-align-items-center is-justify-content-center">
                      <div v-if="absent || !isAllPhotoBeforeEmpty">
                        <div v-for="(photoItem, idx) in dataFotoAfter" :key="idx" class="is-relative mb-3">
                          <!-- <p class="mb-2 label-14">Foto Sebelum Dekat</p> -->
                          <p class="mb-2 label-14"> {{ getPhotoTitle(idx, 'after') }} </p>
                          <div class="wrapper-preview is-relative">
                            <div
                              v-if="photoItem.can_delete && absent"
                              class="x-mark-rounded cursor-pointer"
                              @click="handleDeletePhoto({ idx, status: 'after' })"
                            >
                              <img :src="require('@/assets/img/close-circle.png')" alt="delete-image" />
                            </div>
                            <div
                              v-if="photoItem.preview"
                              class="wrapper-add-file"
                              :class="{ 'cursor-pointer': absent }"
                            >
                              <img :src="photoItem.preview" alt="">
                            </div>
                            <div v-else-if="!photoItem.preview">
                              <img v-if="photoItem.disabled || !absent" src="@/assets/img/upload-photo-disable.png" alt="">
                              <label v-else class="cursor-pointer" for="afterFoto">
                                <input
                                  id="afterFoto"
                                  ref="afterFoto"
                                  type="file"
                                  @change="e => handleFileUpload(e, 'after')"
                                  accept="image/*"
                                  hidden
                                />
                                <img @click="handleTempIndex(idx)" :src="require('@/assets/img/upload-photo.png')" alt="" />
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END BODY -->
        </div>

        <!-- Floating Button -->
        <div class="wrapper-footer-btn">
          <ButtonApp :isSecondary="false" :isBold="true" width="150px" height="46px" @click="handleSubmit">
            Simpan Perubahan
          </ButtonApp>
        </div>
        <!-- Floating Button -->

        <!-- Loading -->
        <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false">
          <SpinnerIcon />
        </b-loading>
        <!-- Loading -->
      </template>
    </ModalTemplate>

    <ModalsPreviewSertifikat
      :isHandleOpenModalsPreviewSertifikat="isHandleOpenModalsPreviewSertifikat"
      :selectWorkers="selectWorkers"
      @closeModalPreviewSertifikat="closeModalPreviewSertifikat"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import Compressor from 'compressorjs'
import { alertErrorMessage } from '@/helpers'
import ButtonApp from '@/components/button/ButtonApp'
import { EditSquare, ProyekEmptyIcon, ClockCircle, SpinnerIcon, AlertIcon, SertifikatBadgeIcon, XMarkIcon } from '@/components/icons'
import confirmOfferingImage from '@/assets/img/delete-area-component.png'
import ModalsPreviewSertifikat from '@/components/modal/ModalsPreviewSertifikat.vue'

export default {
  name: 'ModalChangeAttandance',
  data () {
    return {
      isLoading: false,
      absent: null,
      maxPhotoCount: 2,
      isMobile: false,
      isModalActive: false,
      widthModal: this.width,
      isButtonUploadBefore: null,
      isButtonUploadAfter: null,
      dataFotoBefore: [
        {
          preview: null,
          picture: null,
          can_delete: false
        },
        {
          preview: null,
          picture: null,
          can_delete: false
        }
        // {
        //   preview: null,
        //   picture: null,
        //   can_delete: false
        // }
      ],
      dataFotoAfter: [
        {
          preview: null,
          picture: null,
          can_delete: false,
          disabled: true
        },
        {
          preview: null,
          picture: null,
          can_delete: false,
          disabled: true
        }
        // {
        //   preview: null,
        //   picture: null,
        //   can_delete: false,
        //   disabled: true
        // }
      ],
      time: null,
      isDisabledTime: false,
      jobId: null,

      alreadyUploadBefore: false,
      alreadyUploadAfter: false,
      tempIndex: null,
      isHandleOpenModalsPreviewSertifikat: false,
      selectWorkers: {}
    }
  },
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '53vw'
    },
    dataWorkerAbsent: {
      type: Object,
      default: () => {
        return {}
      }
    }
    // selectWorkers: {
    //   type: Object,
    //   default: () => {
    //     return {}
    //   }
    // }
  },
  components: {
    ButtonApp,
    EditSquare,
    // eslint-disable-next-line vue/no-unused-components
    ProyekEmptyIcon,
    ClockCircle,
    SpinnerIcon,
    AlertIcon,
    ModalTemplate: () => import('@/components/modal/ModalTemplate'),
    SertifikatBadgeIcon,
    ModalsPreviewSertifikat,
    XMarkIcon
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    isAllPhotoBeforeEmpty () {
      return this.dataFotoBefore.every(item => !item.preview)
    }
  },
  watch: {
    showModal (newValue) {
      this.isModalActive = newValue
    },
    width (newValue) {
      this.widthModal = newValue
    },
    absent (value) {
      if (!value) {
        this.isDisabledTime = false
        this.isButtonUploadBefore = false
        this.isButtonUploadAfter = false
      } else {
        this.isDisabledTime = true
        this.isButtonUploadBefore = true
        this.isButtonUploadAfter = true
      }
    },
    dataWorkerAbsent (newVal) {
      this.absent = newVal.attendance_status
      this.jobId = newVal.job_id

      const responseTime = newVal.attendance_date === null ? new Date() : newVal.attendance_date
      this.time = moment(responseTime).format('HH:mm')

      if (newVal.image_photo_before && newVal.image_photo_before.length > 0) {
        this.convertUrlToBlob(newVal.image_photo_before, 'before')

        setTimeout(() => {
          if (newVal.image_photo_after && newVal.image_photo_after.length > 0) {
            this.convertUrlToBlob(newVal.image_photo_after, 'after')
          }
        }, 100)
      }
    },
    dataFotoBefore (val) {
      val.forEach((item, index) => {
        if (!item.preview) {
          const data = {
            preview: null,
            picture: null,
            can_delete: false,
            disabled: true
          }
          this.dataFotoAfter.splice(index, 1, data)
        }
      })
    }
  },
  methods: {
    handleInputTime (event) {
      if (!event.target.value) {
        this.time = null
      }
    },
    clearData () {
      this.dataFotoBefore = [
        {
          preview: null,
          picture: null,
          can_delete: false
        },
        {
          preview: null,
          picture: null,
          can_delete: false
        }
        // {
        //   preview: null,
        //   picture: null,
        //   can_delete: false
        // }
      ]
      this.dataFotoAfter = [
        {
          preview: null,
          picture: null,
          can_delete: false,
          disabled: true
        },
        {
          preview: null,
          picture: null,
          can_delete: false,
          disabled: true
        }
        // {
        //   preview: null,
        //   picture: null,
        //   can_delete: false,
        //   disabled: true
        // }
      ]
      this.absent = null
      this.jobId = null
      this.time = null
    },
    checkScreenSize () {
      this.isMobile = window.innerWidth < 768
    },
    closeModal () {
      this.clearData()
      this.$emit('closeModal')
    },
    handleDeletePhoto (data = {}) {
      const { status, idx } = data

      const photoArray = status === 'before' ? this.dataFotoBefore : this.dataFotoAfter
      const uploadButton = status === 'before' ? this.isButtonUploadBefore : this.isButtonUploadAfter

      const result = photoArray.splice(idx, 1, {
        preview: null,
        picture: null,
        can_delete: false
      })

      if (status === 'before') {
        const data = {
          ...this.dataFotoAfter[idx],
          disabled: true
        }
        this.dataFotoAfter.splice(idx, 1, data)
      }
      this[uploadButton] = !this.absent || photoArray.length >= this.maxPhotoCount

      return result
    },
    handleTempIndex (idx) {
      this.tempIndex = idx
    },
    async handleFileUpload (event, status) {
      const selectedFile = event.target.files[0]

      if (selectedFile) {
        const fileSizeInMB = selectedFile.size / (1024 * 1024)
        const allowedTypes = [
          'image/jpeg',
          'image/png',
          'image/jpg',
          'image/JPEG',
          'image/PNG',
          'image/JPG'
        ]

        // VALIDASI
        if (!allowedTypes.includes(selectedFile.type)) {
          this.$buefy.toast.open({
            message: 'Hanya file gambar yang diperbolehkan (JPEG, JPG, PNG)',
            type: 'is-danger'
          })
          return false
        }

        // VALIDASI
        if (fileSizeInMB.toFixed(2) > this.maxPhotoSizeMB) {
          this.$buefy.toast.open({
            message: 'Gambar melebihi ukuran maksimum yang diizinkan',
            type: 'is-danger'
          })
          return false
        }
      }

      let data
      await this.handleCompressImage(selectedFile).then(results => {
        data = {
          preview: URL.createObjectURL(results),
          picture: results,
          can_delete: true
        }
      })

      if (status === 'before') {
        this.dataFotoBefore.splice(this.tempIndex, 1, data) // dont use this.dataFotoBefore[index] = data
        this.$refs.beforeFoto.value = ''

        const dataAfter = {
          ...this.dataFotoAfter[this.tempIndex],
          disabled: false
        }
        this.dataFotoAfter.splice(this.tempIndex, 1, dataAfter)
      } else {
        this.dataFotoAfter.splice(this.tempIndex, 1, data)
        this.$refs.afterFoto.value = ''
      }

      if (this.dataFotoBefore.length === this.maxPhotoCount) {
        this.isButtonUploadBefore = true
      }

      if (this.dataFotoAfter.length === this.maxPhotoCount) {
        this.isButtonUploadAfter = true
      }
    },
    handleSubmit () {
      if (this.absent === true && (this.time === 'Invalid date' || this.time === null)) {
        this.$buefy.toast.open({
          message: 'Masukkan jam kehadiran terlebih dahulu',
          type: 'is-danger'
        })
        return
      }

      const payload = {
        url: this.url,
        job_id: this.jobId,
        is_present: this.absent === true ? 1 : 0,
        attendance_time: this.time === 'Invalid date' ? null : this.time,
        image_befores: this.dataFotoBefore,
        image_afters: this.dataFotoAfter
      }

      this.$swal({
        imageUrl: confirmOfferingImage,
        imageAlt: 'confirm offering',
        width: '540px',
        padding: '10px 10px 30px 10px',
        title: '<p class="averta-bold label-22" style="margin-bottom: -10px;">Simpan Perubahan</p>',
        html: '<p class="averta-regular">Pastikan seluruh informasi yang diinginkan telah sesuai.</p>',
        showCancelButton: true,
        confirmButtonText: 'Simpan Perubahan',
        cancelButtonText: 'Batalkan',
        reverseButtons: true,
        customClass: {
          confirmButton: 'btn-hapus-detail-component',
          cancelButton: 'btn-batal-detail-component'
        }
      }).then(async (result) => {
        if (result.value) {
          this.isLoading = true
          try {
            const res = await this.$store.dispatch('projectV2/workerAttandance', payload)
            if (!res) {
              alertErrorMessage()
              this.isLoading = false
              this.$emit('reloadPage', this.dataWorkerAbsent.selected_data)
            } else {
              this.clearData()
              this.closeModal()
              this.isLoading = false
              this.$emit('reloadPage', this.dataWorkerAbsent.selected_data)
            }
          } catch (error) {
            alertErrorMessage(error)
            this.isLoading = false
            this.$emit('reloadPage', this.dataWorkerAbsent.selected_data)
          }
        } else if (result.dismiss === 'cancel') {
          // do nothing
        }
      }).catch((error) => {
        alertErrorMessage(error)
      })
    },
    formatDate (date) {
      if (!date) {
        return '-'
      }
      return moment(date).format('DD MMMM YYYY')
    },
    handleCompressImage (image) {
      return new Promise((resolve, reject) => {
        const compressor = new Compressor(image, {
          quality: 0.6,
          mimeType: 'image/jpeg',
          success (result) {
            resolve(result)
          },
          error (err) {
            console.error(err.message)
            reject(err)
          }
        })

        return compressor
      })
    },
    convertUrlToBlob (data, type) {
      if (type === 'before') {
        for (let i = 0; i < data.length; i++) {
          if (Object.keys(data[i]).length > 0) {
            fetch(data[i].image_url || data[i])
              .then(response => response.blob())
              .then(blob => {
                const body = {
                  preview: blob ? URL.createObjectURL(blob) : null,
                  picture: blob || null,
                  can_delete: !!blob
                }
                this.dataFotoBefore.splice(i, 1, body)

                const data = {
                  ...this.dataFotoAfter[i],
                  disabled: false
                }
                this.dataFotoAfter.splice(i, 1, data)
              })
          }
        }
      } else {
        for (let i = 0; i < data.length; i++) {
          if (Object.keys(data[i]).length > 0 && (data[i].image_url || data[i])) {
            fetch(data[i].image_url ? data[i].image_url : data[i])
              .then(response => response.blob())
              .then(blob => {
                const body = {
                  preview: blob ? URL.createObjectURL(blob) : null,
                  picture: blob || null,
                  can_delete: !!blob,
                  disabled: false
                }
                this.dataFotoAfter.splice(i, 1, body)
              })
          }
        }
      }
    },
    handleOpenModalsPreviewSertifikat (workersId, workers) {
      // eslint-disable-next-line vue/no-mutating-props
      this.selectWorkers = { workersId, ...workers }
      this.isHandleOpenModalsPreviewSertifikat = true
    },
    closeModalPreviewSertifikat () {
      this.isHandleOpenModalsPreviewSertifikat = false
    },
    getPhotoTitle (index, type) {
      const titles = {
        before: ['Upload Foto Sebelum', 'Upload Foto Sebelum'],
        after: ['Upload Foto Sesudah', 'Upload Foto Sesudah']
      }
      return titles[type][index] || ''
    }
  },
  mounted () {
    this.checkScreenSize()
    window.addEventListener('resize', this.checkScreenSize)
  },
  created () { },
  beforeDestroy () {
    window.removeEventListener('resize', this.checkScreenSize)
  }
}
</script>

<style scoped>
.content-empty {
  width: 280px;
  margin: auto;
}

.wrapper-empty {
  width: 65%;
  height: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.wrap-body-modal {
  height: 480px;
  overflow-y: auto;
  position: relative;
}

.layout-header {
  padding: 12px 32px;
  background: rgba(16, 189, 65, 0.1);
}

.wrapper-body {
  display: flex;
  flex-direction: row;
  /* padding-bottom: 75px; */
}

.wrapper-body>* {
  flex-grow: 1;
  overflow: auto;
}

.wrapper-footer-btn {
  position: relative;
  bottom: 0px;
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
  background: white;
  padding: 12px 24px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.slot-wrapper {
  padding: 12px;
  border-radius: 12px;
  background: #ffffff;
}

.tukang-img {
  width: 50px;
  height: 50px;
  border-radius: 35px;
  overflow: hidden;
}

.tukang-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.skill-img {
  width: 34px;
  margin-right: 10px;
  border-radius: 35px;
}

.wrapper-skill {
  min-width: auto;
  margin-right: 1em;
}

.wrapper-id {
  min-width: 7.5em;
}

.wrapper-status-hour {
  width: 35%;
  padding: 15px, 30px, 15px, 30px;
}

.wrapper-upload {
  width: 65%;
  padding: 15px, 30px, 15px, 30px;
  height: 265px;
  overflow: auto;
}

.wrapper-radio {
  margin-top: 1rem;
  background: rgba(250, 250, 250, 1);
  border-radius: 12px;
}

.wrapper-hour {
  margin-top: 0.2rem;
}

.wrapper-add-file {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  border-radius: 16px;
  width: 84px;
  height: 84px;
  position: relative;
}

.wrapper-add-file img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.input-time {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  border: 1px solid #e1e1e1;
}

.text-input-wrapper {
  border: 1px solid #e1e1e1;
  padding: 10px 0px;
  padding-left: 1em;
  border-radius: 10px;
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.text-input-wrapper input {
  border: none;
  outline: none;
  font-size: 16px;
  background: transparent;
}

.text-input-wrapper.isDisabled {
  pointer-events: none;
  background: #e1e1e1;
}

.no-clock-icon::-webkit-calendar-picker-indicator {
  display: none;
}

.wrapper-preview {
  width: 84px;
  height: 84px;
  overflow: hidden;
  margin: auto;
}

@media screen and (max-width: 600px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
}
.badge-new-comment {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  min-width: auto;
  max-width: auto;
  height: auto;
  margin: 0;
  padding: 0.25rem 0.5rem;
  background: #ffffff !important;
  border-radius: 1000px;
}
</style>
